import React from 'react';
import battleImage from './butoms/battle.png';

const PlayerInfo = ({ players, view }) => (
  <div className="avatars-container">
    <div className="player-container">
      <div className={view === "game" ? "avatar-game" : "avatar-ready"} style={{ backgroundImage: `url(${players[0]?.avatar})` }}></div>
      <div className="nickname">
        <p>{players[0]?.nickname || 'Esperando jugador 1...'}</p>
      </div>
    </div>
    <div className={view === "game" ? "vs-icon-game" : "vs-icon-ready"}>
      <img src={battleImage} alt="VS" />
    </div>
    <div className="player-container">
      <div className={view === "game" ? "avatar-game" : "avatar-ready"} style={{ backgroundImage: `url(${players[1]?.avatar})` }}></div>
      <div className="nickname">
        <p>{players[1]?.nickname || 'Esperando jugador 2...'}</p>
      </div>
    </div>
  </div>
);

export default PlayerInfo;
