import React, { useEffect } from 'react';
import './css/Countdown3D.css';
import './css/AnimationSquares.css';
import countdownSound from './sounds/MarioKart.mp3'; // Importa el archivo de sonido

const Countdown3D = ({ onComplete }) => {

  useEffect(() => {
    const arrNumbers = [
      [29, 30, 31, 43, 53, 54, 55, 67, 77, 78, 79],
      [29, 30, 31, 43, 53, 54, 55, 65, 77, 78, 79],
      [29, 30, 42, 54, 66, 78],
    ];

    let numero = 0;

    const audio = new Audio(countdownSound); // Crear un nuevo objeto de audio
    audio.play().then(() => {
      function rotateSqr() {
        if (numero >= arrNumbers.length) {
          audio.pause(); // Pausar el sonido cuando la animación termine
          onComplete(); // Llamamos a onComplete cuando la animación termina
          return;
        }

        const arrDrawNumber = arrNumbers[numero];

        for (let block = 26; block < 94; block++) {
          const square = document.getElementById(`sqr${block}`);
          if (square) {
            square.classList.remove('sqrRotate');
          }
        }

        for (let num = 0; num < arrDrawNumber.length; num++) {
          const square = document.getElementById(`sqr${arrDrawNumber[num]}`);
          if (square) {
            square.classList.add('sqrRotate');
          }
        }

        numero++;

        setTimeout(rotateSqr, 1000);
      }

      rotateSqr();
    }).catch(error => {
      console.error("Error al reproducir el sonido:", error);
    });

    return () => {
      audio.pause(); // Asegurarse de que el audio se detenga si el componente se desmonta
      audio.currentTime = 0; // Reiniciar el sonido si el componente se vuelve a montar
    };
  }, [onComplete]);

  useEffect(() => {
    const container = document.getElementById('allSquares');
    if (container) {
      for (let i = 1; i <= 120; i++) {
        const squareDiv = document.createElement('div');
        squareDiv.className = 'square';
        squareDiv.innerHTML = `<div class="sqrInside" id="sqr${i}"></div>`;
        container.appendChild(squareDiv);
      }
    }
  }, []);

  return (
    <div className="countdown-3d-overlay">
      <div id="allSquares" className="container"></div>
    </div>
  );
};

export default Countdown3D;
