import React from 'react';

const GameArea = ({
  gameStarted,
  numbersSubmitted,
  turn,
  playerNumber,
  setPlayerNumber,
  handleNumberSubmit,
  guess,
  setGuess,
  handleGuessSubmit,
  guessResult,
  opponentDisconnected,
}) => (
  <div className="game-area">
    {opponentDisconnected ? (
      <div className="disconnected-message">
        <p>Tu oponente ha abandonado la partida.</p>
      </div>
    ) : (
      <>
        {gameStarted && !numbersSubmitted && (
          <div className="number-input-container">
            <input
              type="text"
              placeholder="Enter your 4 digit number"
              value={playerNumber}
              onChange={(e) => setPlayerNumber(e.target.value)}
              maxLength="4"
              pattern="\d{4}"
              required
              className="number-input"
            />
            <button onClick={handleNumberSubmit} disabled={playerNumber.length !== 4} className="submit-button">
              Send number
            </button>
          </div>
        )}

        {gameStarted && numbersSubmitted && (
          <div className="guess-input-container">
            {turn ? (
              <div>
                <input
                  type="text"
                  placeholder="Ingresa tu intento"
                  value={guess}
                  onChange={(e) => setGuess(e.target.value)}
                  maxLength="4"
                  pattern="\d{4}"
                  required
                  className="guess-input"
                />
                <button onClick={handleGuessSubmit} disabled={guess.length !== 4} className="submit-button">
                  Enviar intento
                </button>
              </div>
            ) : (
              <p>Esperando el turno de tu oponente...</p>
            )}
            {guessResult && <div className="guess-result">{`Dígitos correctos: ${guessResult.correct}`}</div>}
          </div>
        )}
      </>
    )}
  </div>
);

export default GameArea;
