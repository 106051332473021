import React from 'react';

const ReadyButton = ({ playerReady, handleReadyClick, handleNotReadyClick, players }) => (
  <div className="ready-button-container">
    <button
      className={`ready-button ${playerReady ? 'ready' : 'not-ready'}`}
      onClick={playerReady ? handleNotReadyClick : handleReadyClick}
      disabled={players.length < 2}
    >
      {playerReady ? 'Not Ready' : 'Ready'}
    </button>
  </div>
);

export default ReadyButton;
