import React from 'react';
import './css/Balloon.css';

const Balloon = () => {
  return (
    <div className="balloon-container">
      <div className="balloon red"></div>
      <div className="balloon blue"></div>
      <div className="balloon green"></div>
      <div className="balloon yellow"></div>
    </div>
  );
};

export default Balloon;
