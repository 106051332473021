import React from 'react';
import registerIcon from './butoms/register.png';

const NicknameInput = ({ nickname, setNickname, isButtonDisabled, setIsButtonDisabled, isRegistered, setIsRegistered }) => {

  const handleInputChange = (e) => {
    const input = e.target.value;
    // Filtrar solo letras (sin símbolos, números, etc.) y limitar a 16 caracteres
    const filteredInput = input.replace(/[^a-zA-Z]/g, '').slice(0, 16);
    setNickname(filteredInput);
    setIsButtonDisabled(filteredInput.trim() === '');
  };

  return (
    <div className="input-container">
      <input
        type="text"
        className="nickname-input"
        placeholder="Enter Nickname"
        value={nickname}
        onChange={handleInputChange}
        readOnly={isRegistered}
        maxLength={16} // Limita a 16 caracteres
      />
      {!isRegistered && (
        <button className="register-button" onClick={() => setIsRegistered(true)} disabled={isButtonDisabled}>
          <img src={registerIcon} alt="Register Icon" className="icon" />
          <span>Register</span>
        </button>
      )}
    </div>
  );
};

export default NicknameInput;
