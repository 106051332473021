import React from 'react';
import refreshIcon from './butoms/refresh-icon.png';

const AvatarSelector = ({ selectedAvatarIndex, setSelectedAvatarIndex, avatars, isRegistered }) => (
  <div className="avatar" style={{ backgroundImage: `url(${avatars[selectedAvatarIndex]})` }}>
    {!isRegistered && (
      <img
        src={refreshIcon}
        alt="Change Avatar"
        className="refresh-icon"
        onClick={() => setSelectedAvatarIndex(Math.floor(Math.random() * avatars.length))}
      />
    )}
  </div>
);

export default AvatarSelector;
