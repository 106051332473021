import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import './components/css/App.css';
import AvatarSelector from './components/AvatarSelector';
import NicknameInput from './components/NicknameInput';
import RoomActions from './components/RoomActions';
import PlayerInfo from './components/PlayerInfo';
import ReadyButton from './components/ReadyButton';
import GameArea from './components/GameArea';
import Logo from './components/logo.png';
import Confetti from 'react-confetti';
import Balloon from './components/Balloon';
import JoinRoomModal from './components/JoinRoomModal';
import Countdown3D from './components/Countdown3D';
import HomeIcon from './components/butoms/home.png'; // Imagen de la casa

const importAll = (r) => r.keys().map(r);
const avatars = importAll(require.context('./components/avatars', false, /\.(png|jpe?g|svg)$/));

const socket = io('back.guessrival.com');

const App = () => {
  const [nickname, setNickname] = useState('');
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(Math.floor(Math.random() * avatars.length));
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);
  const [room, setRoom] = useState(null);
  const [players, setPlayers] = useState([]);
  const [playerReady, setPlayerReady] = useState(false);
  const [opponentReady, setOpponentReady] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [gameView, setGameView] = useState(false);
  const [playerNumber, setPlayerNumber] = useState('');
  const [turn, setTurn] = useState(false);
  const [guess, setGuess] = useState('');
  const [currentGuess, setCurrentGuess] = useState('');
  const [guessResult, setGuessResult] = useState(null);
  const [guessHistory, setGuessHistory] = useState([]);
  const [numbersSubmitted, setNumbersSubmitted] = useState(false);
  const [winner, setWinner] = useState(null);
  const [showJoinRoomModal, setShowJoinRoomModal] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [opponentDisconnected, setOpponentDisconnected] = useState(false);
  const [showOpponentLeftMessage, setShowOpponentLeftMessage] = useState(false);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);
  const [showForcedLeaveModal, setShowForcedLeaveModal] = useState(false); // Nuevo estado para el modal de forzado a salir

  useEffect(() => {
    socket.on('roomCreated', ({ roomId, players }) => {
      setRoom(roomId);
      setPlayers(players);
    });

    socket.on('roomJoined', ({ players }) => {
      setPlayers(players);
    });

    socket.on('roomFull', () => {
      alert('La sala está llena');
    });

    socket.on('roomNotFound', () => {
      alert('La sala no existe');
    });

    socket.on('playerLeft', ({ players }) => {
      setPlayers(players);
    });

    socket.on('gameStarted', () => {
      setGameStarted(true);
    });

    socket.on('opponentReady', () => {
      setOpponentReady(true);
    });

    socket.on('opponentNotReady', () => {
      setOpponentReady(false);
    });

    socket.on('startCountdown', () => {
      setShowCountdown(true);
    });

    socket.on('yourTurn', () => {
      setTurn(true);
    });

    socket.on('guessResult', (result) => {
      setGuessResult(result);
      setTurn(false);
      setGuessHistory(prevHistory => [...prevHistory, { guess: currentGuess, correct: result.correct }]);
    });

    socket.on('gameOver', ({ winner }) => {
      setWinner(winner);
      setGameStarted(false);
      setTurn(false);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    });

    socket.on('opponentLeft', () => {
      setShowOpponentLeftMessage(true);
      setTimeout(() => {
        setShowOpponentLeftMessage(false);
        handleReturnToHome();
      }, 3000);  // Mostrar el mensaje por 3 segundos
    });

    socket.on('playerForcedToLeave', () => {
      console.log('You have been forced to leave the game because your opponent left.');
      setShowForcedLeaveModal(true);
      setTimeout(() => {
        handleReturnToHome();
        setShowForcedLeaveModal(false);
      }, 3000); // Mostrar el modal por 3 segundos antes de redirigir al lobby
    });

    return () => {
      socket.off('roomCreated');
      socket.off('roomJoined');
      socket.off('roomFull');
      socket.off('roomNotFound');
      socket.off('playerLeft');
      socket.off('gameStarted');
      socket.off('opponentReady');
      socket.off('opponentNotReady');
      socket.off('startCountdown');
      socket.off('yourTurn');
      socket.off('guessResult');
      socket.off('gameOver');
      socket.off('opponentLeft');
      socket.off('playerForcedToLeave');
    };
  }, [currentGuess, room]);

  useEffect(() => {
    if (playerReady && opponentReady) {
      socket.emit('startCountdown', { roomId: room });
    }
  }, [playerReady, opponentReady]);

  const handleCreateRoom = () => {
    socket.emit('createRoom', { nickname, avatar: avatars[selectedAvatarIndex] });
  };

  const handleJoinRoom = (roomId) => {
    socket.emit('joinRoom', { roomId, nickname, avatar: avatars[selectedAvatarIndex] });
    setRoom(roomId);
    setShowJoinRoomModal(false);
  };

  const handleOpenJoinRoomModal = () => {
    setShowJoinRoomModal(true);
  };

  const handleCloseJoinRoomModal = () => {
    setShowJoinRoomModal(false);
  };

  const handleReadyClick = () => {
    socket.emit('ready', { roomId: room });
    setPlayerReady(true);
  };

  const handleNotReadyClick = () => {
    socket.emit('notReady', { roomId: room });
    setPlayerReady(false);
  };

  const handleNumberSubmit = () => {
    socket.emit('submitNumber', { roomId: room, number: playerNumber });
    setNumbersSubmitted(true);
  };

  const handleGuessSubmit = () => {
    if (turn) {
      setCurrentGuess(guess);
      socket.emit('submitGuess', { roomId: room, guess });
      setGuess('');
    } else {
      alert("Es el turno de tu oponente.");
    }
  };

  const handlePlayAgain = () => {
    setWinner(null);
    setGameStarted(false);
    setTurn(false);
    setGuess('');
    setGuessResult(null);
    setGuessHistory([]);
    setNumbersSubmitted(false);
    setPlayerReady(false);
    setOpponentReady(false);
    setGameView(false);
    setOpponentDisconnected(false);
    socket.emit('ready', { roomId: room });
  };

  const handleReturnToHome = () => {
    setRoom(null);
    setWinner(null);
    setGameStarted(false);
    setTurn(false);
    setGuess('');
    setGuessResult(null);
    setGuessHistory([]);
    setNumbersSubmitted(false);
    setPlayerReady(false);
    setOpponentReady(false);
    setGameView(false);
    setOpponentDisconnected(false);
    // No restablezcas nickname ni selectedAvatarIndex
    setShowLeaveConfirmation(false); // Asegúrate de ocultar el modal de confirmación
  };

  const handleCountdownComplete = () => {
    setShowCountdown(false);
    setNumbersSubmitted(false);
    setGameStarted(true);
    setGameView(true);
  };

  const handleLeaveGame = () => {
    setShowLeaveConfirmation(true);  // Mostrar modal de confirmación
  };

  const confirmLeaveGame = () => {
    socket.emit('playerLeftGame', { roomId: room }); // Emitimos un evento al servidor
    setShowLeaveConfirmation(false);
    handleReturnToHome();  // Abandonar el juego y volver al lobby
  };

  const cancelLeaveGame = () => {
    setShowLeaveConfirmation(false);  // Cancelar la acción de abandonar
  };

  return (
    <div className="background">
      {showConfetti && <Confetti />}
      {showConfetti && <Balloon />}
      {showCountdown && <Countdown3D onComplete={handleCountdownComplete} />}
      
      {!room ? (
        <div className="rectangle">
          <img src={Logo} alt="Guess Rival Logo" className="logo" />
          <div className="inner-container">
            <div className="inner-rectangle-left">
              <div className="left-column">
                <AvatarSelector
                  selectedAvatarIndex={selectedAvatarIndex}
                  setSelectedAvatarIndex={setSelectedAvatarIndex}
                  avatars={avatars}
                  isRegistered={isRegistered}
                />
                <NicknameInput
                  nickname={nickname}
                  setNickname={setNickname}
                  isButtonDisabled={isButtonDisabled}
                  setIsButtonDisabled={setIsButtonDisabled}
                  isRegistered={isRegistered}
                  setIsRegistered={setIsRegistered}
                />
              </div>
            </div>
            {isRegistered && (
              <div className="inner-rectangle-right">
                <RoomActions handleCreateRoom={handleCreateRoom} handleJoinRoom={handleOpenJoinRoomModal} />
              </div>
            )}
          </div>
        </div>
      ) : (
        !gameView ? (
          <div className="rectangle">
            <img src={Logo} alt="Guess Rival Logo" className="logo" />
            {!winner ? (
              <>
                <PlayerInfo players={players} view="ready" />
                {!gameStarted && (
                  <ReadyButton
                    playerReady={playerReady}
                    handleReadyClick={handleReadyClick}
                    handleNotReadyClick={handleNotReadyClick}
                    players={players}
                  />
                )}
                <GameArea
                  gameStarted={gameStarted}
                  numbersSubmitted={numbersSubmitted}
                  turn={turn}
                  playerNumber={playerNumber}
                  setPlayerNumber={setPlayerNumber}
                  handleNumberSubmit={handleNumberSubmit}
                  guess={guess}
                  setGuess={setGuess}
                  handleGuessSubmit={handleGuessSubmit}
                  guessResult={guessResult}
                  guessHistory={guessHistory}
                  opponentDisconnected={opponentDisconnected}
                />
                <div className="room-id-container">
                  <p className="room-id">Room ID: {room}</p>
                </div>
              </>
            ) : (
              <div className="winner-view">
                <h2>{winner === socket.id ? '¡Has ganado!' : 'Tu oponente ha ganado.'}</h2>
                <button onClick={handlePlayAgain}>Jugar de nuevo</button>
                <button onClick={handleReturnToHome}>Volver al inicio</button>
              </div>
            )}
            <button className="home-button" onClick={handleLeaveGame}>
              <img src={HomeIcon} alt="Volver al lobby" />
            </button>
          </div>
        ) : (
          <div className="rectangle game-view">
            <img src={Logo} alt="Guess Rival Logo" className="logo" />
          
            <button className="home-button" onClick={handleLeaveGame}>
              <img src={HomeIcon} alt="Volver al lobby" />
            </button>
            
            <div className="game-container">
              <div className="left-section">
                <div className="vs-container">
                  <PlayerInfo players={players} view="game" />
                </div>
                <div className="game-started">
                  <GameArea
                    gameStarted={gameStarted}
                    numbersSubmitted={numbersSubmitted}
                    turn={turn}
                    playerNumber={playerNumber}
                    setPlayerNumber={setPlayerNumber}
                    handleNumberSubmit={handleNumberSubmit}
                    guess={guess}
                    setGuess={setGuess}
                    handleGuessSubmit={handleGuessSubmit}
                    guessResult={guessResult}
                    guessHistory={guessHistory}
                    opponentDisconnected={opponentDisconnected}
                  />
                </div>
              </div>
              <div className="right-section">
                <div className="guess-history">
                  <h2>Historial de Intentos</h2>
                  <ul className="history-list">
                    {guessHistory.slice(-10).map((entry, index) => (
                      <li key={index}>
                        Intento: {entry.guess}, Dígitos correctos: {entry.correct}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="room-id-container">
              <p className="room-id">Room ID: {room}</p>
            </div>
            
          </div>
        )
      )}

      {showJoinRoomModal && (
        <JoinRoomModal onJoin={handleJoinRoom} onCancel={handleCloseJoinRoomModal} />
      )}

      {showOpponentLeftMessage && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>¡Tu oponente ha abandonado la partida!</h2>
            <p>Serás redirigido al lobby en breve.</p>
          </div>
        </div>
      )}

      {showForcedLeaveModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>¡Tu oponente ha abandonado la partida!</h2>
            <p>Serás redirigido al lobby en breve.</p>
          </div>
        </div>
      )}

      {showLeaveConfirmation && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>¿Estás seguro de abandonar la partida?</h2>
            <div className="confirmation-buttons">
              <button onClick={confirmLeaveGame}>Sí</button>
              <button onClick={cancelLeaveGame} className="cancel-button">No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
