import React from 'react';
import createRoomIcon from './butoms/play.png';
import privateRoomIcom from './butoms/private-room.png';
import joinRoomIcon from './butoms/Join.png';
import RandomRoomIcon from './butoms/random.png';
import IARoomIcon from './butoms/buff.png';



const RoomActions = ({ handleCreateRoom, handleJoinRoom }) => (
  <div className="right-column">
    <button onClick={handleCreateRoom}>
      <img src={createRoomIcon} alt="Create Room Icon" className="icon" />
      Create Public Room
    </button>

    <button onClick={handleCreateRoom}>
      <img src={privateRoomIcom} alt="Create Room Icon" className="icon" />
      Create Private Room
    </button>

    <button onClick={handleJoinRoom}>
      <img src={joinRoomIcon} alt="Join Room Icon" className="icon" />
      Join Private Room
    </button>

    <button onClick={handleJoinRoom}>
      <img src={RandomRoomIcon} alt="Join Room Icon" className="icon" />
      Join Random Room
    </button>

    <button onClick={handleJoinRoom}>
      <img src={IARoomIcon } alt="Join Room Icon" className="icon" />
      Multiplayer VS AI
    </button>
  </div>
);

export default RoomActions;
