import React, { useState } from 'react';
import './css/JoinRoomModal.css';

const JoinRoomModal = ({ onJoin, onCancel }) => {
  const [roomId, setRoomId] = useState('');

  const handleJoinClick = () => {
    if (roomId.trim()) {
      onJoin(roomId.trim());
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Unirse a una sala</h2>
        <input
          type="text"
          placeholder="Ingresa el ID de la sala"
          value={roomId}
          onChange={(e) => setRoomId(e.target.value)}
        />
        <div className="modal-buttons">
          <button onClick={handleJoinClick} className="btn-join">Aceptar</button>
          <button onClick={onCancel} className="btn-cancel">Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default JoinRoomModal;
